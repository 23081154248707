/* You can add global styles to this file, and also import other style files */
@import 'shared-styles';
@import 'constants';

:root {
  --colorPrimaryRgb: 1, 169, 202;
  --colorPrimary: rgb(var(--colorPrimaryRgb));
}

body {
  margin: 0;
  height: 100vh;
  font-size: 1.3rem;
  overflow-x: hidden;
}

.formats-app_snackbar {
  position: fixed;
  z-index: 10000000;
  width: 50rem;
  bottom: 2rem;
  left: 8rem;
  display: unset !important;
  filter: none !important;
}
